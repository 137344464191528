import * as React from 'react';
import { TabsWithBackground } from 'components/Tabs/Tabs';
import * as api from './../../api/api';

interface AuthProps {
  closeAuth: () => void;
}

const loginWith = (provider: 'google' | 'facebook' | 'instagram') => () => api.loginWith(provider);

export const Login: React.FunctionComponent<{}> = ({}) => {
  return (
    <div>
      <button {...{ onClick: () => api.loginWithGoogle() }}>Login with Google</button>
      <button {...{ onClick: () => api.loginWithFacebook() }}>Login with Facebook</button>
    </div>
  );
};

export const Auth: React.FunctionComponent<AuthProps> = ({ closeAuth }) => {
  return (
    <>
      <TabsWithBackground
        {...{
          tabs: [
            { key: 'login', string: 'Login', component: <Login /> },
            { key: 'signup', string: 'Register', component: <div>Register Conente</div> },
          ],
          onBackgroundClick: closeAuth,
        }}
      />
    </>
  );
};

export default Auth;
