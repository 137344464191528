import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from './../../theme/theme';
import { Grid } from 'components/TypeGrid/TypeGrid';
import { Platform, TemplateData } from 'components/CardItem/CardItem';

interface TemplateGridProps {
  templateItems: TemplateData[];
  onSelectionChange: (selectedTemplateId: string) => void;
}

export const Container = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  // background: theme.palette.primary,
}));

export const Content = styled(Grid)<{ theme?: Theme }>(({ theme }) => ({
  // background: theme.palette.primary,
  // maxWidth: 960,
  // margin: '0 auto',
  gridGap: theme.gutters.base * 2,
}));

export const Item = styled('li')<{ active: boolean; theme?: Theme }>(({ active, theme }) => ({
  display: 'grid',
  alignItems: 'end',
  height: '100%',
  backgroundColor: theme.palette.primary,
  color: 'white',
  textTransform: 'uppercase',
  paddingLeft: theme.gutters.base,
  paddingBottom: theme.gutters.base,
  boxSizing: 'border-box',
  transition: 'all 0.15s ease-in',
  boxShadow: active ? `0px 0px 0px 4px ${theme.palette.secondary}` : 'none',
}));

export const TemplateGrid: React.FunctionComponent<TemplateGridProps> = ({ templateItems, onSelectionChange }) => {
  const [selected, setSelected] = React.useState<number | null>(null);

  return (
    <Container>
      <Content>
        {templateItems.map((template, ind) => {
          return (
            <Item
              {...{
                key: template._id,
                style: {
                  minHeight: 200,
                },
                active: selected === ind,
                onClick: () => {
                  onSelectionChange(template._id);
                  setSelected(ind);
                },
              }}
            >
              {template.name}
            </Item>
          );
        })}
      </Content>
    </Container>
  );
};

export default TemplateGrid;
