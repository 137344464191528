import React from 'react';
import heroImage from './../../assets/hero-image.jpg';
import { Theme } from 'theme/theme';
import MaxPageWidth from 'components/MaxPageWidth/MaxPageWidth';
import styled from '@emotion/styled';
import * as api from './../../api/api';

interface HeroProps {}

export const Container = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
}));
export const Image = styled('img')<{ theme?: Theme }>(({ theme }) => ({
  objectFit: 'cover',
  width: '100%',
}));

export const Title = styled('h1')<{ theme?: Theme }>(({ theme }) => ({
  textTransform: 'uppercase',
  gridArea: 'title',
  display: 'grid',
  justifyItems: 'center',
  alignContent: 'center',
  fontSize: '3rem',
}));

export const Subtitle = styled('h3')<{ theme?: Theme }>(({ theme }) => ({
  textTransform: 'uppercase',
  gridArea: 'subtitle',
  display: 'grid',
  justifyItems: 'center',
  alignContent: 'center',
  fontWeight: 300,
}));

export const TextContainer = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  fontFamily: theme.font.family,
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 25%)',
  gridTemplateAreas: `
    '. . title title'
    '. . subtitle subtitle'
  `,
}));

export const Hero: React.FunctionComponent<HeroProps> = ({}) => {
  return (
    <Container>
      <Image {...{ src: heroImage }} />
      <MaxPageWidth>
        <TextContainer>
          <Title>advertise it on the fly</Title>
          <Subtitle>Our advanced AI-based, automated engine turns static content into entertaining videos that drive user engagement to new heights.</Subtitle>
          <button
            {...{
              onClick: () => {
                console.log('click');
                api.getTemplates({ type: 'YOUTUBE' });
              },
            }}
          >
            Get Templates
          </button>
          <button
            {...{
              onClick: () => {
                console.log('click');
                api.getUser();
              },
            }}
          >
            Get User
          </button>
        </TextContainer>
      </MaxPageWidth>
    </Container>
  );
};

export default Hero;
