import * as React from 'react';
import styled from '@emotion/styled';
import logo from './../../assets/logo-footer.png';
import { Theme } from 'theme/theme';
import { Link } from 'react-router-dom';
import { Logo } from 'components/Navbar/Navbar';

import facebookIcon from './../../assets/icon-facebook.png';
import instagramIcon from './../../assets/icon-instagram.png';
import twitterIcon from './../../assets/icon-twitter.png';
import pinterestIcon from './../../assets/icon-pinterest.png';
import youtubeIcon from './../../assets/icon-youtube.png';

const socialIcons: Record<SocialNetworks, string> = {
  facebook: facebookIcon,
  instagram: instagramIcon,
  twitter: twitterIcon,
  pinterest: pinterestIcon,
  linkedin: facebookIcon,
  youtube: youtubeIcon,
};

interface FooterProps {}

// export const Logo = styled('img')({});

export const Container = styled('nav')<{ theme?: Theme }>(({ theme }) => ({
  padding: theme.gutters.base,
  backgroundColor: theme.palette.primary,
  color: 'white',
  fontFamily: theme.font.family,

  display: 'grid',
  gridTemplateColumns: 'auto',
  justifyItems: 'center',
  gridGap: theme.gutters.base * 2,

  [`@media (min-width: ${theme.breakpoints.tablet}px)`]: {
    gridTemplateColumns: 'min-content 1fr',
    justifyItems: 'start',
  },
}));

export const InnerContainer = styled('nav')<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
}));

const SocialNetwork = styled('ul')<{ numOfItems: number; isDrawerOpen?: boolean; theme?: Theme }>(({ theme, numOfItems, isDrawerOpen }) => ({
  margin: 0,
  padding: 0,
  display: 'grid',
  gridTemplateColumns: `auto repeat(${numOfItems}, auto)`,
  listStyle: 'none',
  alignItems: 'center',
  gridGap: theme.gutters.base,
  transition: 'all 30ms ease-in',

  [`@media (min-width: ${theme.breakpoints.desktop}px)`]: {
    // backgroundColor: theme.palette.secondary,
    gridTemplateColumns: `auto repeat(${numOfItems}, auto)`,
  },
  '& li': {
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
    display: 'grid',
    justifyContent: 'center',
  },
}));

export const SmallLinks = styled('p')<{ theme?: Theme }>(({ theme }) => ({
  textTransform: 'uppercase',
  color: 'white',
  fontSize: 11,
  // display: 'flex',
  // alignItems: 'center',

  '& a': {
    color: 'white',
  },
}));

type SocialNetworks = 'facebook' | 'instagram' | 'youtube' | 'linkedin' | 'pinterest' | 'twitter';

// "tos": "https://www.comovid.com/termsandconditions.html",
// "privacy": "https://www.comovid.com/termsandconditions.html"

const menuItems: { key: SocialNetworks; linkTo: string }[] = [
  { key: 'facebook', linkTo: 'https://www.facebook.com/comovid' },
  { key: 'instagram', linkTo: '' },
  { key: 'twitter', linkTo: 'https://twitter.com/ComovidAi' },
  { key: 'pinterest', linkTo: '' },
  { key: 'linkedin', linkTo: 'https://www.linkedin.com/company/comovid/' },
  { key: 'youtube', linkTo: '' },
];

const Icon = styled('img')({
  display: 'block',
  maxWidth: 20,
  cursor: 'pointer',
});

export const Footer: React.FunctionComponent<FooterProps> = ({}) => {
  const numOfItems = menuItems.length;
  return (
    <Container>
      <Logo {...{ src: logo }} />
      <InnerContainer>
        <SocialNetwork {...{ numOfItems }}>
          {menuItems.map(item => (
            <li {...{ key: item.key }}>
              <Icon {...{ src: socialIcons[item.key] }} />
            </li>
          ))}
        </SocialNetwork>
        <SmallLinks>
          © COMOVID All Rights Reserved | <Link {...{ to: '/terms-of-service' }}>TOS</Link> | <Link {...{ to: '/privacy-policy' }}>Privacy Policy</Link> | Design By CASIRER
        </SmallLinks>
      </InnerContainer>
      <a href='http://pagepeeker.com/site-snapshot/' target='_blank'>Site Snapshot by PagePeeker</a>
    </Container>
  );
};

export default Footer;
