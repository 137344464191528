import * as React from 'react';
import Page from 'components/Page/Page';
import TypeGrid from 'components/TypeGrid/TypeGrid';
import Products from 'components/Products/Products';
//import styled from '@emotion/styled';

interface DashboardProps {}

export const Dashboard: React.FunctionComponent<DashboardProps> = ({}) => {
  const showTypePicker = false;
  return (
    <Page>
      {showTypePicker && <TypeGrid />}
      <Products />
    </Page>
  );
};

export default Dashboard;
