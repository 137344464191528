import React from 'react';
import styled from '@emotion/styled';
import logo from './assets/logo.png';
import './App.css';
import Home from './pages/home';
import Navbar from './components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import Tabs from 'components/Tabs/Tabs';
import Auth from 'components/Auth/Auth';
import CardItem from 'components/CardItem/CardItem';
import MaxPageWidth from 'components/MaxPageWidth/MaxPageWidth';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, RouteProps } from 'react-router-dom';
import { Theme } from 'theme/theme';
import Dashboard from 'pages/dashboard';

// const store = {
//   signinPopup: false,
// };

// const Context = React.createContext({
//   signinPopup: false,
// });

function checkIfAuthenticated(): boolean {
  var params = new URLSearchParams(window.location.search);

  const token = params.get('comovid-token');

  if (token) {
    window.localStorage.setItem('comovid-token', token);
    window.history.replaceState({}, '', `${window.location.origin}${window.location.pathname}`);
    return true;
  } else {
    const storedToken = window.localStorage.getItem('comovid-token');
    if (storedToken) {
      return true;
    }
  }
  return false;
}

export const PageLayout = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridTemplateRows: 'auto 1fr auto',
  minHeight: '100vh',
  background: theme.palette.primary,
}));

const PrivateRoute: React.FunctionComponent<{} & RouteProps> = ({ children, ...rest }) => {
  const isAuthenticated = checkIfAuthenticated();

  // const isAuthenticated = false;
  console.log('children', children);
  return (
    <Route
      {...{
        ...rest,
        render: ({ location }) => (isAuthenticated ? children : <Redirect {...{ to: { pathname: '/', state: { from: location } } }} />),
      }}
    />
  );
};

const App: React.FC = () => {
  const isAuthenticated = checkIfAuthenticated();
  const [isAuthOpen, setAuthState] = React.useState(false);
  return (
    <Router>
      <div className="App">
        {isAuthOpen && <Auth {...{ closeAuth: () => setAuthState(false) }} />}
        <PageLayout>
          <Navbar {...{ isAuthenticated, openAuth: () => setAuthState(true) }} />
          <Switch>
            <PrivateRoute path="/template">
              <div>Template</div>
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer />
        </PageLayout>
      </div>
    </Router>
  );
};

export default App;
