import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'theme/theme';
import wixLogo from './../../assets/logo-wix.png';
import shopifyLogo from './../../assets/logo-shopify.png';
// import MaxPageWidth from './../../components/MaxPageWidth/MaxPageWidth';
import MaxPageWidth from 'components/MaxPageWidth/MaxPageWidth';

interface SiteURLProps {}

const HEIGHT = 47;

const Container = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  // display: 'grid',
  // gridGap: 8,
  // gridTemplateColumns: 'auto min-content min-content',
  backgroundColor: theme.palette.primary,
  padding: '8px 0',
}));

const Grid = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'grid',
  gridGap: 8,
  gridTemplateColumns: 'auto min-content min-content',
  backgroundColor: theme.palette.primary,
  padding: theme.gutters.base,
}));

export const Input = styled('input')<{ theme?: Theme }>(({ theme }) => ({
  // backgroundColor: theme.palette.secondary,
  // border: 'none',
  height: HEIGHT,
  padding: 0,
  boxSizing: 'border-box',
  fontSize: 20,
  fontWeight: 100,
}));

export const Button = styled('button')<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.secondary,
  border: 'none',
  height: HEIGHT,
  boxSizing: 'border-box',
  cursor: 'pointer',
}));

const Image = styled('img')<{ theme?: Theme }>(({ theme }) => ({
  display: 'block',
  height: '100%',
  //   backgroundColor: theme.palette.secondary,
  //   border: 'none',
  //   height: HEIGHT,
}));

export const SiteURL: React.FunctionComponent<SiteURLProps> = ({}) => {
  return (
    <Container>
      <MaxPageWidth>
        <Grid>
          <Input type="text" placeholder="Your store URL" />
          <Button>
            <Image {...{ src: wixLogo }} />
          </Button>
          <Button>
            <Image {...{ src: shopifyLogo }} />
          </Button>
        </Grid>
      </MaxPageWidth>
    </Container>
  );
};

export default SiteURL;
