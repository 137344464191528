import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme/theme';

export const MaxPageWidth = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  maxWidth: 960,
  margin: '0 auto',
  width: '100%',
}));

export default MaxPageWidth;
