import color from 'color';

export interface Theme {
  palette: {
    primary: string;
    secondary: string;
    paleBlue: string;
    white: string;
    black: string;
  };
  breakpoints: {
    mobile: number;
    tablet: number;
    desktop: number;
    widescreen: number;
    fullhd: number;
  };
  gutters: {
    base: 8;
  };
  font: {
    family: string;
  };
  zIndex: {
    navbar: number;
    auth: number;
  };
}

export const theme: Theme = {
  palette: {
    primary: '#41638b',
    secondary: '#ff2b4f',
    white: '#fff',
    black: '#000',
    get paleBlue() {
      return color(this.primary)
        .lighten(0.7)
        .hex()
        .toString();
    },
  },
  breakpoints: {
    mobile: 0,
    tablet: 769,
    desktop: 1024,
    widescreen: 1216,
    fullhd: 1408,
  },
  gutters: {
    base: 8,
  },
  font: {
    family: '"Montserrat", sans-serif',
  },
  zIndex: {
    navbar: 1000,
    auth: 1100,
  },
};
