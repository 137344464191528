import * as React from 'react';
import styled from '@emotion/styled';
import logo from './../../assets/logo.png';
import menu from './../../assets/menu-white.png';
import close from './../../assets/close-white.png';
import { Theme } from 'theme/theme';
import { useHistory } from 'react-router-dom';

interface NavbarProps {
  openAuth: () => void;
  isAuthenticated: boolean;
}

export const Container = styled('nav')<{ theme?: Theme }>(({ theme }) => ({
  padding: theme.gutters.base,
  // paddingBottom: 0,
  backgroundColor: theme.palette.primary,
  display: 'grid',
  gridTemplateColumns: 'min-content auto',
  alignItems: 'start',
  fontFamily: theme.font.family,
  position: 'relative',

  '&:after': {
    // content: '""',
    // marginLeft: -8,
    // position: 'relative',
    // zIndex: 100,
    // top: '100%',
    // width: 0,
    // height: 0,
    // borderLeft: '0px solid transparent',
    // borderRight: '1000px solid transparent',
    // borderTop: `50px solid ${theme.palette.primary}`,
  },
}));

export const Logo = styled('img')({
  maxWidth: 100,
  cursor: 'pointer',
});

export const List = styled('ul')<{ numOfItems: number; isDrawerOpen?: boolean; theme?: Theme }>(({ theme, numOfItems, isDrawerOpen }) => ({
  margin: 0,
  padding: 0,
  display: isDrawerOpen ? 'grid' : 'none',
  gridTemplateColumns: 'auto',
  listStyle: 'none',
  alignItems: 'center',
  gridGap: theme.gutters.base,
  transition: 'all 500ms ease',
  // background: 'coral',
  // transform: `translateX(${isDrawerOpen ? 0 : 1000}px)`,
  height: isDrawerOpen ? 'auto' : 0,
  opacity: isDrawerOpen ? 1 : 0,
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  zIndex: theme.zIndex.navbar,
  backgroundColor: theme.palette.primary,

  // paddingTop: '100%',

  [`@media (min-width: ${theme.breakpoints.desktop}px)`]: {
    gridTemplateColumns: `auto repeat(${numOfItems}, auto)`,
    transform: 'unset',
    height: 'auto',
    display: 'grid',
    opacity: 1,
    top: 'unset',
    position: 'relative',

    '& img': {
      display: 'none',
    },
  },
  '& li': {
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
  },
}));

export const ListItem = styled('li')<{ theme?: Theme }>(({ theme }) => ({
  cursor: 'pointer',
}));

export const AuthButton = styled('li')<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.secondary,
  padding: theme.gutters.base,
  cursor: 'pointer',
}));

export const MenuIcon = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '100%',

  '& img': {
    maxWidth: 32,
    maxHeight: 32,
    cursor: 'pointer',
  },
  [`@media (min-width: ${theme.breakpoints.desktop}px)`]: {
    display: 'none',
  },
}));

function logout(): void {
  window.localStorage.removeItem('comovid-token');
  window.history.pushState({}, '', '/');
}

const menuItems = ['Home', 'About Us', 'How does it work', 'Pricing', 'Latest Videos', 'FAQ', 'Contact us', 'Register'];

export const Navbar: React.FunctionComponent<NavbarProps> = ({ openAuth, isAuthenticated }) => {
  const history = useHistory();
  const isMobile = false;
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  console.log('TCL: isDrawerOpen', isDrawerOpen);
  const numOfItems = menuItems.length;

  return (
    <Container>
      <Logo {...{ onClick: () => history.push('/'), src: logo }} />
      <div {...{ style: { height: isDrawerOpen ? 'auto' : '100%' } }}>
        <List {...{ isDrawerOpen, numOfItems }}>
          {menuItems.map(item => (
            <ListItem {...{ key: item }}>{item}</ListItem>
          ))}
          <AuthButton
            {...{
              onClick: isAuthenticated
                ? () => {
                    logout();
                    history.push('/');
                  }
                : openAuth,
            }}
          >
            {isAuthenticated ? 'Logout' : 'Login'}
          </AuthButton>
        </List>
      </div>
      <MenuIcon />
      {/* {isMobile && <img {...{ src: isDrawerOpen ? close : menu }} />} */}
      {/* <div>
        <List {...{ isDrawerOpen, numOfItems }}>
          {menuItems.map(item => (
            <li {...{ key: item }}>{item}</li>
          ))}
          <AuthButton>LOGIN</AuthButton>
        </List>
        <div
          {...{
            style: {
              // position: 'absolute',
              // top: 8,
              // right: 8,
              color: 'white',
              cursor: 'pointer',
            },
            onClick: () => setDrawerOpen(!isDrawerOpen),
          }}
        >
          
        </div>
      </div> */}
    </Container>
  );
};

export default Navbar;
