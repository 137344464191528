import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from './../../theme/theme';

interface PageProps {}

export const Container = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  background: theme.palette.primary,
}));

export const Content = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  background: theme.palette.primary,
  maxWidth: 960,
  margin: '0 auto',
}));

export const Page: React.FunctionComponent<PageProps> = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

export default Page;
