import axios from 'axios';
import { apiUrl } from './../config/envs';

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
});

export const loginWith = (provider: 'google' | 'facebook' | 'instagram') => {
  return api.get(`/auth/${provider}?var=elephant`);
};

export const loginWithGoogle = () => {
  // window.location.replace(`${apiUrl}/auth/google`);
  // TODO - make var come from .env
  return (window.location.href = `${apiUrl}/auth/google?var=elephant`);
};

export const loginWithFacebook = () => {
  // const appId = '784997131961125';
  const appId = '1352973081577988';
  const redirectUri = 'http://localhost:3000';
  const state = { st: 10 };
  // window.location.replace(`${apiUrl}/auth/facebook`);
  return (window.location.href = `
  https://www.facebook.com/v5.0/dialog/oauth?
  client_id=${appId}
  &redirect_uri=${redirectUri}
  &state=${JSON.stringify(state)}`);
};

const getToken = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('code');

  return token;
};
// TODO Login / Register

// ? JWT -
// TODO Add Site - input field (shopify / wix)
export interface AddSitePayload {
  //   user: {};
  //   field: {};
  url: string;
  //   description: 'string';
  //   thumbnailUrl: 'string';
}

// let token = getToken();
// let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1Nzk1NDk5ODksImV4cCI6MTYxMTA4NTk4OX0.oxkQiSDCX36T3ugqgXONFYXJKX4-5X2nYF1pFD4i1eM';
let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1Nzk1NTA3NTgsImV4cCI6MTYxMTA4Njc1OH0.pSeU72EJtY4ITDsYOvLkC-Won6CGTSSs36hEEs9ealQ';

// const atoken = '4/vQEmc4ciLpaXIZUG77BMdEE3k0EIS9Puz0hlvVqbW6sqOknWFNgXM2EMa0Zb3C3OWxW8qeXIFNmcZvkHyzDCQh0';

export const addSite = (payload: AddSitePayload) => {
  return api.post('/sites', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// TODO Submit Collection Grid Business Type - []

export const submitCollectionType = (payload: { type: string }) => {
  return api.post('/collection', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// TODO Get Products (N > 10,000)
export const getProducts = () => {
  // TODO - Pagination?
  return api.get('/products', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUser = () => {
  // TODO - Pagination?
  return api.get('/users', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// TODO Get Templates Data
export type TemplateType = 'YOUTUBE' | 'FACEBOOK' | 'INSTAGRAM';
export const getTemplates = (payload: { type: TemplateType }) => {
  return api.get('/templates', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
interface SubmitOrderPayload {}

// TODO Submit Order
export const submitOrder = (payload: SubmitOrderPayload) => {
  return api.post('/order', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
