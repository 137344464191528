import * as React from 'react';
import styled from '@emotion/styled';
import logo from './../../assets/logo.png';
import { Theme, theme } from 'theme/theme';
import { lightenDarkenColor } from './../../utils/colors';
import color from 'color';

interface TabsProps {
  tabs: Array<{
    key: string;
    component: React.ReactNode;
    string: string;
  }>;
  onBackgroundClick?: () => void;
}

export const Background = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: theme.zIndex.auth,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  backgroundColor: color(theme.palette.primary)
    .darken(0.3)
    .alpha(0.9)
    .rgb()
    .toString(),
}));

export const Container = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: 'transparent',
  minWidth: 400,
  fontFamily: theme.font.family,
}));
export const Header = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  maxWidth: 200,
}));

export const Tab = styled('div')<{ isActive: boolean; theme?: Theme }>(({ theme, isActive }) => ({
  padding: `${theme.gutters.base}px ${theme.gutters.base * 2}px`,
  textTransform: 'uppercase',
  cursor: 'pointer',
  maxWidth: 200,
  backgroundColor: ((isActive
    ? color(theme.palette.primary)
        .lighten(0.7)
        .hex()
    : color(theme.palette.primary)
        .lighten(0.95)
        .whiten(0.5)
        .hex()) as unknown) as string,
}));

export const Content = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: (color(theme.palette.primary)
    .lighten(0.7)
    .hex() as unknown) as string,
  padding: 32,
}));

const menuItems = ['TOS', 'Privacy Policy'];

export const Tabs: React.FunctionComponent<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Container
      {...{
        onClick: e => {
          e.stopPropagation();
        },
      }}
    >
      <Header>
        {tabs.map((tab, ind) => {
          return <Tab {...{ key: tab.key, isActive: ind === activeTab, onClick: () => setActiveTab(ind) }}>{tab.string}</Tab>;
        })}
      </Header>
      <Content>{tabs[activeTab].component}</Content>
    </Container>
  );
};

export const TabsWithBackground: React.FunctionComponent<TabsProps> = ({ tabs, onBackgroundClick }) => {
  return (
    <Background
      {...{
        onClick: e => {
          console.log('click');
          onBackgroundClick && onBackgroundClick();
        },
      }}
    >
      <Tabs
        {...{
          tabs,
        }}
      />
    </Background>
  );
};

export default Tabs;
