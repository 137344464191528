import * as React from 'react';
import SiteURL from 'components/SiteURL/SiteURL';
import Hero from '../components/Hero/Hero';	

interface HomeProps {}

export const Home: React.FunctionComponent<HomeProps> = ({}) => {
  return (
    <div>
      <Hero/>
      <SiteURL />
    </div>
  );
};

export default Home;
