import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from './../../theme/theme';
import publisherIcon from './../../assets/publisher.png';
import travelIcon from './../../assets/travel.png';
import realestateIcon from './../../assets/realestate.png';
import ecommerceIcon from './../../assets/ecommerce.png';
import foodIcon from './../../assets/food.png';

interface PageProps {}

export type Type = 'publisher' | 'travel' | 'realestate' | 'ecommerce' | 'food';

interface Item {
  key: string;
  type: Type;
}

export const Container = styled('section')<{ theme?: Theme }>(({ theme }) => ({
  // background: 'green',
  fontFamily: theme.font.family,
}));
export const Image = styled('img')<{ theme?: Theme }>(({ theme }) => ({
  display: 'block',
  objectFit: 'contain',
  width: '100%',
}));

export const Grid = styled('ul')<{ theme?: Theme }>(({ theme }) => ({
  listStyle: 'none',
  width: '100%',
  padding: 0,
  margin: 0,
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, minmax(30%, 1fr))`,
  gridGap: theme.gutters.base * 2,

  '& li': {
    position: 'relative',
    cursor: 'pointer',
    img: {
      transition: 'all 0.35s ease',
      filter: 'blur(4px) grayscale(70%)',
    },
    '&:hover img': {
      filter: 'blur(0px)',
    },
  },
}));

export const Text = styled('p')<{ theme?: Theme }>(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '2rem',
  textShadow: `0 0 10px ${theme.palette.white}`,
}));

const icons: Record<Type, string> = {
  publisher: publisherIcon,
  travel: travelIcon,
  realestate: realestateIcon,
  ecommerce: ecommerceIcon,
  food: foodIcon,
};

const items: Item[] = [
  { key: 'publisher', type: 'publisher' },
  { key: 'travel', type: 'travel' },
  { key: 'realestate', type: 'realestate' },
  { key: 'ecommerce', type: 'ecommerce' },
  { key: 'food', type: 'food' },
];

export const TypeGrid: React.FunctionComponent<PageProps> = ({ children }) => {
  return (
    <Container>
      <h3>Please select the category best describes your business type</h3>
      <Grid>
        {items.map((item, ind) => {
          return (
            <li {...{ key: item.key, onClick: e => window.alert('clicked - ' + item.type) }}>
              <Image {...{ src: icons[item.type] }} />
              <Text>{item.type.toUpperCase()}</Text>
            </li>
          );
        })}
      </Grid>
    </Container>
  );
};

export default TypeGrid;
