import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from './../../theme/theme';
import publisherIcon from './../../assets/publisher.png';
import travelIcon from './../../assets/travel.png';
import realestateIcon from './../../assets/realestate.png';
import ecommerceIcon from './../../assets/ecommerce.png';
import foodIcon from './../../assets/food.png';
import { Container, Grid as BaseGrid } from 'components/TypeGrid/TypeGrid';
import CardItem, { Product, templates } from 'components/CardItem/CardItem';

interface PageProps {}

// export type Type = 'publisher' | 'travel' | 'realestate' | 'ecommerce' | 'food';

// interface Item {
//   key: string;
//   type: Type;
// }

// export const Container = styled('section')<{ theme?: Theme }>(({ theme }) => ({
//   // background: 'green',
//   fontFamily: theme.font.family,
// }));
// export const Image = styled('img')<{ theme?: Theme }>(({ theme }) => ({
//   display: 'block',
//   objectFit: 'contain',
//   width: '100%',
// }));

export const Grid = styled(BaseGrid)<{ theme?: Theme }>(({ theme }) => ({
  //   listStyle: 'none',
  //   width: '100%',
  //   padding: 0,
  //   margin: 0,
  //   display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, minmax(50%, 1fr))`,
  //   gridGap: theme.gutters.base * 2,
  //   '& li': {
  //     position: 'relative',
  //     cursor: 'pointer',
  //     img: {
  //       transition: 'all 0.35s ease',
  //       filter: 'blur(4px) grayscale(70%)',
  //     },
  //     '&:hover img': {
  //       filter: 'blur(0px)',
  //     },
  //   },
}));

// export const Text = styled('p')<{ theme?: Theme }>(({ theme }) => ({
//   position: 'absolute',
//   top: 0,
//   right: 0,
//   bottom: 0,
//   left: 0,
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   fontSize: '2rem',
//   textShadow: `0 0 10px ${theme.palette.white}`,
// }));

// const icons: Record<Type, string> = {
//   publisher: publisherIcon,
//   travel: travelIcon,
//   realestate: realestateIcon,
//   ecommerce: ecommerceIcon,
//   food: foodIcon,
// };

// const items: Item[] = [
//   { key: 'publisher', type: 'publisher' },
//   { key: 'travel', type: 'travel' },
//   { key: 'realestate', type: 'realestate' },
//   { key: 'ecommerce', type: 'ecommerce' },
//   { key: 'food', type: 'food' },
// ];
export const product: Product = {
  sourceId: 'foofoo',
  _id: '123',
  meta: {
    title: 'Very Good Product',
    desc: 'Description la la la',
    thumbnailSrc: '',
  },
  platforms: ['facebook'],
  templates: templates,
};

export const productsData: { [k: string]: string } = {
  key1: 'value1',
  key2: 'aalex',
  key3: 'ayossi',
  key4: 'ynir',
  key55: 'mamoshe',
  key33: 'ayossi',
  key44: 'mamynir',
  key551: 'mamamoshe',
};

const products = [product];

export const Input = styled('input')<{ theme?: Theme }>(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  borderBottom: '1px solid white',
  fontFamily: theme.font.family,
  fontSize: '1rem',
  width: '100%',
  marginBottom: theme.gutters.base * 4,
}));

export const Products: React.FunctionComponent<PageProps> = ({ children }) => {
  const productList = Object.keys(productsData).map(key => ({ sku: key, title: productsData[key] }));
  const [filteredList, filterList] = React.useState<{ sku: string; title: string }[]>(productList);
  const [filterValue, setFilterValue] = React.useState<string>('');

  React.useEffect(() => {
    console.log('filterValue', filterValue);
    // filterList(Object.keys(filteredList).filter(key => key.includes(filterValue) || filteredList[key].includes(filterValue)));
    filterList(productList.filter(item => item.sku.includes(filterValue) || item.title.includes(filterValue)).slice(0, 5));
  }, [filterValue]);
  console.log('filteredList', filteredList);
  return (
    <Container>
      <h3>Products</h3>
      <p>Please select the products you'd like to generate. It's very easy</p>
      <Input
        {...{
          placeholder: 'Please start typing SKU / Product Title',
          value: filterValue,
          onChange: e => setFilterValue(e.target.value),
        }}
      />

      <Grid>
        {filteredList.map((productItem, ind) => {
          return (
            <CardItem
              {...{
                onChange: () => window.alert('bloop'),
                checked: true,
                key: productItem.sku,
                product: {
                  ...product,
                  // meta: {
                  // ...product.meta,
                  // title: productItem.title,
                  // },
                },
              }}
            >
              Item{/* <Image {...{ src: icons[item.type] }} />
              <Text>{item.type.toUpperCase()}</Text> */}
            </CardItem>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Products;
