import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'theme/theme';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Carousel, { Template } from 'components/Carousel/Carousel';
import { Grid } from 'components/TypeGrid/TypeGrid';
import { Tabs } from 'components/Tabs/Tabs';
import { Button } from 'components/SiteURL/SiteURL';
import TemplateGrid from 'components/TemplateGrid/TemplateGrid';

interface CardItemProps {
  product: Product;
  checked?: boolean;
  onChange: any;
}

export interface TemplateData {
  _id: string;
  user: string;
  name: string;
  type: string;
  description?: string;
  price: number;
  duration: number;
  fileUrl: string;
}

export type Platform = 'facebook' | 'instagram' | 'youtube';
export interface Product {
  sourceId: string;
  _id: string;
  meta: {
    title: string;
    desc: string;
    thumbnailSrc: string;
  };
  platforms: Platform[];
  //////////////////
  templates: Partial<Record<Platform, TemplateData[]>>;
}

// const filterFunc = arr => arr.filter(item => template[item]);
// [templates[checkedPlatforms]];
// const MAX_HEIGHT = 140

export const Container = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.paleBlue,
}));

export const Wrap = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'grid',
  cursor: 'pointer',
  // gridTemplateColumns: 'minmax(100px, auto) minmax(min-content, 200px) 1fr auto auto',
  gridTemplateColumns: 'minmax(100px, auto) 1fr',
  alignItems: 'center',
  gridGap: theme.gutters.base * 2,
  //   maxHeight: 140,
  padding: theme.gutters.base * 2,
  fontFamily: theme.font.family,
  //   maxWidth: 100,

  '& p': {
    fontSize: 12,
  },
}));

export const Checkmark = styled(() => <input type="checkmark" />)<{ theme?: Theme }>(({ theme }) => ({}));
export const Image = styled('img')<{ theme?: Theme; src?: string }>(({ theme, src }) => ({
  backgroundColor: src ? 'none' : 'coral',
  border: 'none',
  display: 'block',
  width: '100%',
  maxWidth: 100,
  height: '100%',
  maxHeight: 100,
}));

export const PlatformPicker = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

// export const templates: Template[] = ;

// return {
//   _id: '0',
//   user: 'a template user',
//  name: 'template 0',
//  type: 'type',
//  description: 'some template description, totally optional',
//  price: 200000,
//  duration: 100,
//  fileUrl: ''
// },

// export const templates: Record<Platform, TemplateData[]> = {
//   facebook: [0,1,3,4,5].map<TemplateData>((key, ind) => {
// return {
// }
//   }),

// };

export const templates: Product['templates'] = {
  facebook: ['a', 'b', 'c', 'd', 'e', 'f', 'j'].map<TemplateData>((key, ind) => {
    return {
      _id: key + ind,
      user: 'a template user',
      name: `facebook template ${ind}`,
      type: 'type',
      description: 'some template description, totally optional',
      price: 20000,
      duration: 100,
      fileUrl: '',
    };
  }),
  instagram: ['a', 'b', 'c', 'd', 'e', 'f', 'j'].map<TemplateData>((key, ind) => {
    return {
      _id: key + ind,
      user: 'a template user',
      name: `instagram template ${ind}`,
      type: 'type',
      description: 'some template description, totally optional',
      price: 20000,
      duration: 100,
      fileUrl: 'a',
    };
  }),
  youtube: ['a', 'b', 'c', 'd', 'e', 'f', 'j'].map<TemplateData>((key, ind) => {
    return {
      _id: key + ind,
      user: 'a template user',
      name: `youtube template ${ind}`,
      type: 'type',
      description: 'some template description, totally optional',
      price: 20000,
      duration: 100,
      fileUrl: 'a',
    };
  }),
};

console.log('tempaltes', templates);

export const CardItem: React.FunctionComponent<CardItemProps> = ({
  product,
  checked = true,
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => console.log(e.target.checked),
}) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('');
  // const platform = platforms;
  // const platform = 'facebook';
  const { templates } = product;
  console.log('templates', templates);
  return (
    <Container>
      <Wrap {...{ onClick: e => setExpanded(!isExpanded) }}>
        {/* <Checkbox {...{ checked, onChange }} /> */}
        <Image {...{}} />
        <div>
          <h4>{product.meta.title}</h4>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nobis ratione in optio cupiditate recusandae eaque voluptates quasi atque natus provident enim omnis nulla
            {/* ducimus, saepe tempore! Nihil quas aspernatur explicabo. */}
          </p>
        </div>
      </Wrap>
      {isExpanded && (
        <div
          {...{
            style: {
              padding: 32,
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'center',
            },
          }}
        >
          <Button
            {...{
              onClick: e => window.alert('selected template id ' + selectedTemplateId),
              style: { alignSelf: 'end', color: 'white', textTransform: 'uppercase' },
            }}
          >
            Add To Cart
          </Button>
          <Tabs
            {...{
              tabs: Object.keys(templates).map((platform: string) => {
                const templateItems = templates[platform as Platform] || [];

                return {
                  key: platform,
                  string: platform.toUpperCase(),
                  component: <TemplateGrid {...{ templateItems, onSelectionChange: id => setSelectedTemplateId(id) }} />,
                };
              }),
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default CardItem;
